import { FormattedMessage } from 'utils/intl';

import Button from '@components/atoms/Button';

import classes from './DeleteProduct.module.scss';
import messages from './DeleteProduct.messages';

type Props = {
  onClick: () => void;
};

const DeleteProduct = ({ onClick }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <Button
      color="secondary"
      variant="outlined"
      size="sm"
      classes={{ root: classes.removeButton, content: classes.removeButtonContent }}
      onClick={onClick}
    >
      <FormattedMessage {...messages.removeProduct} />
    </Button>
  </div>
);

export default DeleteProduct;
